//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { clearAllBodyScrollLocks, enableBodyScroll } from "body-scroll-lock";
import { numberWithSpaces, orderBy } from "~/util/helpers";
import ProductPopupDragAndDropMixin from "~/mixins/ProductPopupDragAndDropMixin";
import { isIndexPage } from "~/service/catalog-router/helpers";

import { sendYandexMetric } from "~/service/ecommerce";
import Arrow from "~/components/icons/Arrow.vue";
import BurgerClose from "~/components/icons/BurgerClose.vue";
import StopListMixin from "~/mixins/StopListMixin";
import ProductTags from "~/components/products/ProductTags.vue";
import cityLinksHelper from "~/util/city-links-helper";
import AttractiveOffers from "~/components/products/AttractiveOffers.vue";
import ModifierProduct from "~/components/products/ModifiersProduct.vue";
import ProductDiscountTimer from "~/components/products/ProductDiscountTimer.vue";

export default {
    name: "ProductPopup",
    components: { ProductDiscountTimer, ModifierProduct, AttractiveOffers, ProductTags, BurgerClose, Arrow },
    mixins: [ProductPopupDragAndDropMixin, StopListMixin],
    data() {
        return {
            arrCommentIds: null,
            productPopupEl: null,
            indent: null,
            shiftY: null,
            onClose: null,
            windowSize: null,
            top: 0,
            isInvisible: true,
            fullModifiersInfo: null,
            scrollInnerTopValue: 0,
            checkModifiers: false,
            scrollOuterTopValue: 0,
            popupScrollValue: 0,
            importedComponent: null,
            selectedProductMod: null,

            initSlider: true,
            hidePrevButton: false,
            hideNextButton: false,
            productsInCategory: [],
            categoryIndex: null,
            isLoadedProduct: false,
            slideRotation: null,

            stopText: null,
        };
    },
    computed: {
        ...mapState("products", ["products"]),
        ...mapGetters("products", ["getProductFullInfo", "getCategories"]),
        ...mapGetters("cart", ["isCartLoaded", "closestDepartments", "productsInCart"]),
        ...mapGetters("productPopup", ["isShow", "isModifiersButtonClicked"]),
        ...mapState("productPopup", [
            "product",
            "colorProduct",
            "gift",
            "sel_modifiers",
            "isLoading",
            "selModifiersPrice",
            "disableChange",
            "openInCart",
            "openIn",
        ]),
        ...mapGetters("delivery", ["deliveryAddress", "deliverySelfDepartemnt", "city"]),
        ...mapGetters("modals", ["anyOpenModals"]),
        addressSelected() {
            return (
                (this.deliveryAddress && this.deliveryAddress.street !== undefined) || this.deliverySelfDepartemnt
            );
        },
        selModifiers() {
            if (this.sel_modifiers) {
                return this.sel_modifiers;
            }
            return [];
        },
        baseProduct() {
            return this.selectedProductMod || this.product;
        },
        isAndroid() {
            if (process.client) {
                return document.querySelector(".product-popup-wrap") &&
                    document.querySelector(".product-popup__inner")
                    ? document.querySelector(".product-popup-wrap").offsetHeight !==
                          document.querySelector(".product-popup__inner").offsetHeight
                    : false;
            }

            return false;
        },
        productIsDisabled() {
            if (!this.product.remainings || !this.closestDepartments?.length) {
                return;
            }

            return this.closestDepartments.every((department) => {
                return (
                    (this.product.remainings?.[department.id] !== null && department.active) || !department.active
                );
            });
        },
        productInCart() {
            return this.productsInCart?.find((prod) => {
                const cartProdModIds = orderBy((prod.sel_modifiers || []).map((m) => m.id)).join("_");
                const payloadModIds = orderBy(
                    (this.sel_modifiers || this.fullModifiersInfo?.modifiers || []).map((m) => m.id)
                ).join("_");

                return this.product.id == prod.id && payloadModIds == cartProdModIds;
            });
        },
        productCount() {
            return this.productInCart?.quantity;
        },
        currentCategory() {
            const currentCategory = this.getCategories?.find((category) => category.id == this.product.category_id);
            return currentCategory || [];
        },
        currentCategoryLink() {
            if (this.currentCategory) {
                return this.$catalogRouter.getCategoryPath(this.currentCategory);
            }
            return null;
        },
        routeIsCatalog() {
            return isIndexPage(this.$router.currentRoute);
        },
        isShowStop() {
            return this.addressSelected && this.stopText;
        },

        slideClass() {
            const slideMap = {
                left: this.isLoadedProduct ? "slide-out-right" : "slide-in-left",
                right: this.isLoadedProduct ? "slide-out-left" : "slide-in-right",
            };
            return this.slideRotation && !this.initSlider ? slideMap[this.slideRotation] : null;
        },
    },
    watch: {
        isShow: {
            handler(newVal, oldVal) {
                this[newVal === true ? "onShow" : "onHide"]();
                this.initSlider = true;
                if (newVal && !this.openInCart) {
                    if (this.product) {
                        this.categoryIndex = this.getCategories.findIndex(
                            (category) => category.id === this.product?.category_id
                        );
                        this.getCategoryProducts();
                    }
                }
            },
            immediate: true,
        },
        product: {
            handler(value) {
                if (value) {
                    this.selectedProductMod = null;
                    this.setProductComments(null);
                    this.stopText = this.getStopListInfo({ product: this.baseProduct });
                    if (!this.openInCart) {
                        const productIndex = this.productsInCategory.findIndex(
                            (product) => product.id === this.product.id
                        );
                        this.hidePrevButton = !this.categoryIndex && !productIndex;
                        this.hideNextButton =
                            this.categoryIndex === this.getCategories.length - 1 &&
                            productIndex === this.productsInCategory.length - 1;
                    } else {
                        const productIndex = this.productsInCart.findIndex(
                            (product) => product.id === this.product.id
                        );
                        this.hidePrevButton = !productIndex;

                        this.hideNextButton = productIndex === this.productsInCart.length - 1;
                    }
                }
            },
            deep: true,
        },
        products: {
            handler(value) {
                if (value && this.product) {
                    const inCatalog = value.find((catProduct) => catProduct.id === this.product.id);
                    if (!inCatalog) {
                        this.close();
                    } else {
                        this.setProduct(inCatalog);
                    }
                }
            },
        },
    },
    mounted() {
        if (this.isShow) {
            this.onShow();
        }
        this.$nuxt.$on("popup-product-close-function", this.setOnCloseFunction);
        this.openModalContent("ProductEnergy");

        this.stopText = this.getStopListInfo({ product: this.baseProduct });
    },
    beforeDestroy() {
        this.onHide();
        this.$nuxt.$off("popup-product-close-function", this.setOnCloseFunction);
    },
    methods: {
        ...mapActions("products", {
            loadProductById: "loadProductById",
        }),
        ...mapMutations("productPopup", ["setProduct"]),
        ...mapMutations("products", ["setModifiersErrors"]),
        ...mapActions("modals", ["openModal"]),
        handleBaseRoute() {
            window.scroll(0, 0);
            this.close();
        },

        productAdded() {
            if (this.$screen.width < 480) {
                this.closeMobileAnimation();
            } else {
                this.close();
            }
        },
        async close(isHistory = true) {
            console.log("** clearing productPopup on ProductPopup close");
            const backPath = this.openIn;
            this.$store.dispatch("productPopup/clearState");
            this.selectedProductMod = null;
            // this.resetState();
            this.$store.commit("productPopup/setModifiersButtonClicked", false);
            this.setModifiersErrors();
            document.body.classList.remove("open-modal-default");
            document.body.classList.remove("modal-open");

            if (this.$screen.width > 480) {
                const header = document.querySelector(".js-wrap-top-line");
                header.addEventListener("transitionend", this.fullClose);
            }

            if (backPath) {
                await this.$router.push({ path: backPath });
            }

            window.removeEventListener("popstate", this.closeWithoutHistory);
        },
        closeWithoutHistory() {
            if (this.$screen.width < 480) {
                this.closeMobileAnimation(false);
            } else {
                this.close(false);
            }
        },
        fullClose() {
            document.body.classList.remove("modal-open");
            if (this.$device.isIos) {
                enableBodyScroll(this.innerWrap);
            }
            if (typeof this.onClose === "function") {
                this.onClose();
                this.onClose = null;
            }
            if (this.styleModal) {
                this.styleModal.remove();
            }

            if (this.productPopupEl) {
                this.productPopupEl.classList.remove("animate");
                this.productPopupEl.removeEventListener("transitionend", this.fullClose);
            }

            const header = document.querySelector(".js-wrap-top-line");
            header.removeEventListener("transitionend", this.fullClose);
        },
        setProductComments(arrCommentIds) {
            this.arrCommentIds = arrCommentIds;
        },
        async addProduct() {
            await this.$refs.productActions.addProduct();
        },
        async removeProduct() {
            await this.$refs.productActions.removeProduct();
        },
        formatValue(value) {
            return numberWithSpaces(value);
        },
        setCurrentModifier(modifiers) {
            this.fullModifiersInfo = modifiers;
        },
        openModalContent(component) {
            this.importedComponent = component;
        },
        setSelectedProductMod(productMod) {
            this.selectedProductMod = productMod;
        },
        removeData() {
            this.fullModifiersInfo = null;
            this.setProductComments(null);
        },
        onHide() {
            if (!process.client) {
                return;
            }
            // this.resetState();

            this.$nuxt.$off("Enter-pressed");
            this.$nuxt.$off("Enter-pressed");

            clearAllBodyScrollLocks();
            this.removeData();
        },
        openAddressSelector() {
            sendYandexMetric("first-choice-address");
            this.close();
            this.openModal({
                modalName: "DeliveryMap",
                modalData: { chosenCity: this.city },
            });
        },
        scrollToCategory(path) {
            this.$nuxt.$emit("scroll-to-path", path);
            this.close();
        },

        // Product slide start

        getCategoryProducts() {
            const category = this.getCategories[this.categoryIndex];
            this.productsInCategory = this.products.filter((product) =>
                product.categories_ids?.includes(category?.id)
            );
        },
        nextCategory() {
            if (this.categoryIndex < this.getCategories.length - 1) {
                this.categoryIndex += 1;
                this.getCategoryProducts();
                this.nextProductHandler();
            }
        },
        prevCategory() {
            if (this.categoryIndex > 0) {
                this.categoryIndex -= 1;
                this.getCategoryProducts();
                this.showProduct(this.productsInCategory[this.productsInCategory.length - 1]);
            }
        },

        nextProductHandler() {
            this.initSlider = false;
            this.isLoadedProduct = true;
            this.slideRotation = "right";
            if (!this.openInCart) {
                const index = this.productsInCategory.findIndex((product) => product.id === this.product.id);
                if (index < this.productsInCategory.length - 1) {
                    this.showProduct(this.productsInCategory[index + 1]);
                } else {
                    this.nextCategory();
                }
            } else {
                const index = this.productsInCart.findIndex((product) => product.id === this.product.id);
                if (index < this.productsInCart.length - 1) {
                    this.showProduct(this.productsInCart[index + 1]);
                }
            }
        },

        prevProductHandler() {
            this.initSlider = false;
            this.isLoadedProduct = true;
            this.slideRotation = "left";

            if (!this.openInCart) {
                const index = this.productsInCategory.findIndex((product) => product.id === this.product.id);
                if (index > 0) {
                    this.showProduct(this.productsInCategory[index - 1]);
                } else {
                    this.prevCategory();
                }
            } else {
                const index = this.productsInCart.findIndex((product) => product.id === this.product.id);
                if (index > 0) {
                    this.showProduct(this.productsInCart[index - 1]);
                }
            }
        },

        async showProduct(product) {
            this.removeData();

            if (!this.openInCart) {
                await this.$router.push({
                    path: this.productLink(product),
                });
            } else {
                this.$store.dispatch("productPopup/showProductByPayload", {
                    product,
                    disableChange: true,
                    sel_modifiers: product.sel_modifiers,
                    openInCart: true,
                });
            }

            const el = document.querySelector(".product-popup__inner-over");
            el.addEventListener(
                "animationend",
                (e) => {
                    if (e.animationName.includes("slide-out")) {
                        this.isLoadedProduct = false;
                    }
                },
                false
            );
        },
        productLink(product) {
            return cityLinksHelper.getNuxtLinkToPath(
                this.$catalogRouter.getProductPath(product),
                cityLinksHelper.getCityIdFromVuex(this.$store)
            );
        },
        changeModStop(data) {
            if (data) {
                this.stopText = this.getStopListInfo({ product: this.baseProduct, stopMods: data });
            } else {
                this.stopText = this.getStopListInfo({ product: this.baseProduct });
            }
        },
    },
};
