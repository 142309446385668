import { Config } from "./types";
import { applyOverridenConfigs } from "./helpers";

const config: Config = {
    IS_TEST_SITE: "0",
    API_URL: "https://api.eda1.ru/api",
    CLIENT_AUTH_WS_URL: "wss://messengers-integration-service.eda1.ru:443/",
    RABBIT_PROXY_WS_URL: "wss://shop-bar-socket.eda1.ru",

    TELEGRAM_AUTH_BOT_USERNAME: "Sushkof_i_DelPesto_bot",
    WHATSAPP_AUTH_PHONE: "79676399505",

    GOOGLE_PAY_ENV: "PRODUCTION", // TEST, PRODUCTION

    // SERVER_STORAGE_DUMPS_PATH: false,
    IS_STORAGE_DUMP_ENABLED: "1",
    USE_IMAGE_RESIZER: "1",
    IMAGE_RESIZER_SERVICE_URL: "https://img.eda1.ru/im/",
    LOG_BASE_URL: "/api/logs",
    MENU_CATALOG_SERVICE_URL_SERVER: "http://159.69.157.244:8085",
    MENU_CATALOG_SERVICE_URL_CLIENT: "http://159.69.157.244:8085",

    ORDER_STATUS_READY_ID: "80",

    GEO_HELPER_URL: "https://dev-geo-service.eda1.ru",
    PERSONALISATION_SERVICE_URL: "http://195.201.149.184:8001/",

    BRAND_ID: "1",

    APP_LINK: "https://redirect.appmetrica.yandex.com/serve/173298119751064848",
};

applyOverridenConfigs(config);

export default config;
