//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { getAbsolutePriceString } from "product-modifier-groups/lib";
import Minus from "~/components/icons/Minus.vue";
import Plus from "~/components/icons/Plus.vue";
import AllCustomButtons from "~/components/common/AllCustomButtons.vue";
import ProductBeforeDiscountPrice from "~/components/products/ProductBeforeDiscountPrice.vue";
import CustomCounter from "~/components/common/CustomСounter.vue";
import ProductEnergy from "~/components/products/ProductEnergy.vue";
import StopListMixin from "~/mixins/StopListMixin";

export default {
    name: "ModifierProductGroup",
    mixins: [StopListMixin],
    components: { ProductEnergy, CustomCounter, ProductBeforeDiscountPrice, AllCustomButtons, Plus, Minus },
    props: ["group", "type", "selectedModifiers", "modifiers", "inCart", "product", "isEmptyGroup"],
    data() {
        return {
            openModPopup: false,
            popupTrigger: null,
        };
    },
    computed: {
        ...mapState("metrika", ["paramsAB"]),
        isAllowStacking() {
            return this.group.allow_stacking_identical_dishes;
        },
        isMainType() {
            return this.type === "main";
        },
        isComplimentType() {
            return this.type === "compliment";
        },
        isMultiType() {
            return this.type === "multi";
        },
        isShowCounter() {
            return this.group.max_count > 0;
        },
        modifiersNoStop() {
            if (this.isMultiType) {
                return this.noStopModifiers(this.noDuplicateMods(this.modifiers));
            }
            return this.noDuplicateMods(this.modifiers);
        },
        groupInStop() {
            // добавить логику подсчета
            if (this.isMultiType) {
                if (this.isAllowStacking) {
                    const countMaxChose = this.modifiersNoStop.reduce(
                        (sum, current) => sum + current.max_number_to_choose,
                        0
                    );
                    return countMaxChose < this.group.min_modifiers_count;
                } else {
                    return this.modifiersNoStop.length < this.group.min_modifiers_count;
                }
            }
            return this.modifiersNoStop.length <= 0;
        },
        noHasDefaultMod() {
            return !this.modifiersNoStop.find((mod) => mod.is_default);
        },
    },
    mounted() {
        if (this.isMainType && this.modifiersNoStop && !this.groupInStop && this.noHasDefaultMod) {
            this.chooseModifiers(this.modifiersNoStop[0]);
        }
    },
    watch: {
        noHasDefaultMod(nv) {
            if (nv && this.isMainType && this.modifiersNoStop && !this.groupInStop) {
                this.chooseModifiers(this.modifiersNoStop[0]);
            }
        },
    },
    methods: {
        noDuplicateMods(mods) {
            return Array.from(new Map(mods.map((item) => [item.id, item])).values());
        },
        isShowModifier(mod) {
            if (this.inCart) {
                return this.selectedModifiers.find((selMod) => selMod.id === mod.id);
            }
            return true;
        },
        displayWithPicture() {
            return this.group?.visual_type == 1;
        },
        displayWithPictureLeft() {
            return this.group?.visual_type === 3;
        },
        typeOfSymbol() {
            if (this.group.is_choice_required) {
                return " / ";
            }
            if (this.group.is_complement) {
                return " +";
            }
            return "";
        },
        chooseModifiers(modifierByGroup) {
            this.$emit("chooseModifiers", { group: this.group, modifierByGroup });
        },
        isModifierSelected(modifier) {
            // if open from cart
            if (this.product.sel_modifiers) {
                return this.product.sel_modifiers.find((item) => {
                    return item.id == modifier.id;
                });
            }

            return this.selectedModifiers.find((item) => {
                return item.id == modifier.id;
            });
        },
        modifierPhotoUrl(modifier) {
            return modifier?.photo || modifier?.photos?.[0] || this.group?.empty_photo;
        },
        getPrice(modifier) {
            return getAbsolutePriceString(modifier.price);
        },
        modifierQuantity(modifierByGroup) {
            const mod = this.selectedModifiers.find((selMod) => selMod.id === modifierByGroup.id);
            return mod ? mod.quantity : 0;
        },

        isMaxQuantityModifier(mod) {
            const maxToChoose = mod.max_number_to_choose || this.group.max_modifiers_count;
            if (!this.isAllowStacking && (this.isMultiType || this.isComplimentType)) {
                return this.modifierQuantity(mod) >= 1;
            }
            return this.modifierQuantity(mod) >= maxToChoose;
        },
        countSelectedModifiersInGroup() {
            const count = this.selectedModifiers
                .filter((selMod) => this.modifiers.find((groupMod) => selMod.id === groupMod.id))
                .reduce((acc, obj) => {
                    return acc + obj.quantity;
                }, 0);

            return {
                count,
                isMax: count >= this.group.max_modifiers_count,
            };
        },
        plusModifier(modifierByGroup) {
            this.$emit("plusModifier", {
                group: this.group,
                modifierByGroup,
                isMaxInGrop: this.countSelectedModifiersInGroup().isMax,
                isMaxModifier: this.isMaxQuantityModifier(modifierByGroup),
            });
        },
        minusModifier(modifierByGroup) {
            this.$emit("minusModifier", { group: this.group, modifierByGroup });
        },
        clickRemoveModifiersByGroupId() {
            this.$emit("clickRemoveModifiersByGroupId", this.group.id);
        },

        showModPopup(mod) {
            this.popupTrigger = this.$refs[`elem-${mod.id}`][0];
            this.setProductModalValues({ product: mod });

            if (Object.keys(this.$store.getters["products/getProductEnergyInfo"]).length > 0) {
                this.openModPopup = true;
            }
        },
        hideModPopup() {
            this.openModPopup = false;
            this.setProductModalValues(null);
        },
        setProductModalValues(values) {
            this.$store.dispatch("products/changeCurrentProductEnergyValue", values);
        },
    },
};
