import { Plugin } from "@nuxt/types";
import { Context, Inject } from "@nuxt/types/app";
import VueRouter, { RawLocation, Route, RouteRecordPublic } from "vue-router";
import { CatalogRouter } from "../service/catalog-router/index";
import { setContext } from "../service/context-keeper";
import cityLinksHelper from "../util/city-links-helper";
import launchCityHelper, { LaunchCityItem } from "../service/launch-city-helper";

// function interopDefault(promise) {
//     return promise.then(m => m.default || m);
// }

// Сравнивает окончания путей без GET-параметров
function isTheSameRoutePath(oldPath: string, newPath: string): boolean {
    return oldPath.split("?")[0].endsWith(newPath.split("?")[0]);
}

const catalogRedirectRoute: RawLocation = { name: "index" };

const exportPlugin: Plugin = async (ctx: Context, inject: Inject): Promise<void> => {
    const catalogRouter: CatalogRouter = new CatalogRouter();
    const router: VueRouter | undefined = ctx.app.router;

    setContext(ctx);
    inject("catalogRouter", catalogRouter);

    if (!router) {
        return;
    }

    // На клиенте вызовем при инициализации плагина, т.к. продукты уже есть в store
    if (process.client && ctx.app.store) {
        await catalogRouter.fillByVuexStore(ctx.app.store);
    }

    // Вызовем инициализацию Vuex в одном из хуков маршрута, т.к. он срабатывает раньше
    router.beforeEach(async (to: Route, from: Route, next) => {
        if (ctx.app.store && !ctx.app.store.state.isServerInitialized && !process.client) {
            // Назначим наше свойство, чтобы узнать, по какому маршруту перешли
            (ctx as any).to_route = to;
            // На сервере - после nuxtServerInit, чтобы сначала заполнился каталог
            await ctx.app.store.dispatch("nuxtServerInit", ctx);
            await catalogRouter.fillByVuexStore(ctx.app.store);
        }

        next();
    });

    // Проверка авторизации на пути вида /user/...
    router.beforeEach((to: Route, from: Route, next) => {
        if (!ctx.app || !ctx.app.store) {
            next();
            return;
        }

        if (!to.fullPath.includes("/user/")) {
            next();
            return;
        }

        if (!ctx.app.store.getters["auth/isLoggedIn"] && to.name !== "user-mystery-shoper") {
            next(catalogRedirectRoute);
            return;
        }

        next();
    });

    // Статичные лендинги запуска городов без приписки города
    // router.beforeEach((to: Route, from: Route, next) => {
    //     const launchCityItem: LaunchCityItem | undefined = launchCityHelper.getLaunchCityItemByPath(to.path);
    //     const cityLaunchPage: RouteRecordPublic | undefined = router.getRoutes().find((r) => r.name == "new_city");

    //     if (to.name !== "error_404") {
    //         next();
    //         return;
    //     }

    //     if (launchCityItem && cityLaunchPage?.name) {
    //         router.addRoute(cityLaunchPage.name, {
    //             path: to.path,
    //             components: cityLaunchPage.components,
    //             name: cityLaunchPage.name,
    //         });

    //         (ctx as any).strict_route = true;
    //         next(to.fullPath);
    //         return;
    //     }

    //     next();
    // });

    // Обработка города
    router.beforeEach((to: Route, from: Route, next) => {
        if (!router) {
            next();
            return;
        }

        if ((ctx as any).strict_route) {
            next();
            return;
        }

        if (!ctx.app || !ctx.app.store) {
            next();
            return;
        }

        const newFullPath: string = cityLinksHelper.getNuxtLinkToPath(
            cityLinksHelper.cleanCityPath(to.fullPath),
            cityLinksHelper.getCityIdFromVuex(ctx.app.store)
        );

        // Удалить блок когда вернут Челябинск
        if (newFullPath.includes("chelyabinsk") && !newFullPath.includes("/landing_preview/129")) {
            next("/landing_preview/129");
            return;
        }
        if (!newFullPath.includes("chelyabinsk") && newFullPath.includes("/landing_preview/129")) {
            next("/");
            return;
        }

        // Редирект на лендинг нового города
        const launchCityItem: LaunchCityItem | undefined = launchCityHelper.getLaunchCityItemByPath(to.path);

        if (launchCityItem && to.path.includes(launchCityItem.path) && !to.path.includes("new_city")) {
            next(`${launchCityItem.path}/new_city`);
            return;
        }

        if (to.path.includes("new_city") && !launchCityItem) {
            next("/");
        }

        // Если старый и новый пути (без GET-параметров) одинаковы, НЕ редиректим
        if (isTheSameRoutePath(to.fullPath, newFullPath)) {
            next();
            return;
        }

        // Возникала ошибка при прокрутке вверх от категорий к баннерам (но вроде бы победили):
        // Uncaught (in promise) Error: Redirected when going from "/chelyabinsk/yaponskaya-kuhnya/zavtraki" to "/" via a navigation guard
        next(newFullPath);
    });

    // Обработка каталога (категории, продукты, теги) и страниц старого сайта
    router.beforeEach((to: Route, from: Route, next) => {
        const cleanedPath = cityLinksHelper.cleanCityPath(to.path);
        const catalogRoute: RouteRecordPublic | undefined = router.getRoutes().find((r) => r.name == "index");
        const pagesRoute: RouteRecordPublic | undefined = router.getRoutes().find((r) => r.name == "pages");

        if (!router) {
            next();
            return;
        }

        if (to.name !== "error_404") {
            next();
            return;
        }

        // Пробуем обработать как урл каталога

        const category = catalogRouter.getCategoryByPath(cleanedPath);
        // если такого пути нет, но в пути есть категория редиректим на нее
        if (catalogRoute?.name && !catalogRouter.doesPathExist(cleanedPath) && category) {
            const categoryPath = category?.paths.find((path) => cleanedPath.includes(path));
            router.addRoute(catalogRoute.name, {
                path: cityLinksHelper.getRouterRegExpPath(categoryPath),
                components: catalogRoute.components,
            });
            next(categoryPath);
            return;
        }

        if (catalogRoute && catalogRoute.name && catalogRouter.doesPathExist(cleanedPath)) {
            // Добавляем виртуальный роут
            router.addRoute(catalogRoute.name, {
                // name: catalogRoute.name,
                path: cityLinksHelper.getRouterRegExpPath(cleanedPath),
                components: catalogRoute.components,
            });

            // Снова перенаправляем на этот же путь для того, чтобы сработал новый роут
            next(to.fullPath);
            return;
        }

        if (to.query.catalog !== undefined) {
            next(catalogRedirectRoute);
            return;
        }

        if (to.fullPath == "/privacy" && ctx.app.store) {
            router.addRoute({ path: "/privacy", components: catalogRoute?.components });
            ctx.app.store.dispatch("account/showPersonalDataProcessingAgreement");
            next(to.fullPath);
            return;
        }

        // Пробуем обработать как урл страницы сайта
        if (pagesRoute && pagesRoute.name) {
            router.addRoute(pagesRoute.name, {
                path: cityLinksHelper.getRouterRegExpPath(cleanedPath),
                components: pagesRoute.components,
            });

            next(to.fullPath);
            return;
        }

        next();
    });

    // Требуется определить мета-данные уже после всех пройденных коллбэков и редиректов
    router.beforeEach(async (to: Route, from: Route, next) => {
        if (ctx.app.store && !ctx.app.store.state.isAfterInitDone && !process.client) {
            (ctx as any).to_route = to;
            await ctx.app.store.dispatch("afterNuxtServerInit", ctx);
        }
        next();
    });
};

export default exportPlugin;
