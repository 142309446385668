import axios from "axios";
import { NuxtCookies } from "cookie-universal-nuxt";
import config from "../../config/index";
import { SharedContextData } from "../storages/abstract-storage/shared-context-data";
import { coalesceCookiesValue } from "../cookies-helper";
import { DELIVERY_TYPE } from "../../util/types";
import { MenuCategory, MenuProduct, MenuStopList, CatalogApiRequestResult } from "~/util/api.types";

type RequestParams = { [key: string]: string };

export class CatalogGetter {
    private readonly cityId: number | null = null;

    private readonly departmentId: number | null = null;

    private readonly deliveryType: string | null = null;

    private guestToken: string | null = null;

    private multiDepartments: string | null = null;

    private recommendationsFlag: string | null = null;

    constructor(
        cityId: number | null = null,
        departmentId: number | null = null,
        deliveryType: string | null = null,
        multiDepartments = null
    ) {
        this.cityId = cityId;
        this.departmentId = departmentId;
        this.deliveryType = deliveryType;
        this.multiDepartments = multiDepartments;
    }

    static createFromContext(ctx: { $cookies: NuxtCookies }): CatalogGetter {
        const cookies: NuxtCookies | undefined = ctx["$cookies"]; /* eslint-disable-line dot-notation */
        return new CatalogGetter(
            coalesceCookiesValue(cookies, "city_id", null),
            coalesceCookiesValue(cookies, "department_id", null),
            coalesceCookiesValue(cookies, "delivery_type", null),
            coalesceCookiesValue(cookies, "multi_departments", null)
        );
    }

    static createFromSharedContextData(ctxData: SharedContextData): CatalogGetter {
        const data = ctxData.getData();
        return new CatalogGetter(
            data.cityId ? +data.cityId : null,
            data.departmentId ? +data.departmentId : null,
            data.deliveryType
        );
    }

    private getRequestParams(): RequestParams {
        const output: RequestParams = {
            company: "1",
            is_new_tags: "1",
            type_delivery: this.deliveryType ?? DELIVERY_TYPE.DELIVERY,
            flatten_categories: "1",
            ignore_schedule: "1",
            is_webp: "1",
        };

        if (this.cityId) {
            output.city = `${this.cityId}`;
        }
        if (this.departmentId) {
            output.department = `${this.departmentId}`;
        }

        if (this.multiDepartments) {
            output.multi_departments = this.multiDepartments;
        }
        if (this.guestToken && this.guestToken.length > 0) {
            output.guest_token = `${this.guestToken}`;
        }
        if (this.recommendationsFlag) {
            output.recommendations = this.recommendationsFlag;
        }

        if (config.BRAND_ID) {
            output.brand_id = config.BRAND_ID;
        }

        return output;
    }

    private async fetchCatalogByBaseUrls(rawBaseUrls: string | string[]): Promise<CatalogApiRequestResult | null> {
        const baseUrls: string[] = Array.isArray(rawBaseUrls) ? rawBaseUrls : [rawBaseUrls];
        const paramsString = new URLSearchParams(this.getRequestParams()).toString();

        for (const baseUrl of baseUrls) {
            const url = `${baseUrl}/catalog?${paramsString}`;

            let resp: any = null;
            try {
                // eslint-disable-next-line no-await-in-loop
                resp = await axios.get(url);
                if (!resp?.data?.categories?.length) {
                    console.log(
                        `Catalog request URL - ${url}`,
                        `full resp ${JSON.stringify(resp)}`,
                        `Date: ${new Date()}`
                    );
                }
            } catch (err) {
                console.log(
                    `Catalog request URL - ${url}`,
                    "Ошибка в запросе каталога:",
                    JSON.stringify(err),
                    `Date: ${new Date()}`
                );
            }

            if (!resp || !resp.data || !resp.data.products) {
                continue;
            }

            return resp.data;
        }

        return null;
    }

    async get(): Promise<CatalogApiRequestResult | null> {
        const baseUrl: string | string[] | undefined = process.client
            ? config.MENU_CATALOG_SERVICE_URL_CLIENT
            : config.MENU_CATALOG_SERVICE_URL_SERVER;
        if (!baseUrl || baseUrl.length === 0) {
            throw new Error("No menu catalog service base url provided in config");
        }

        return await this.fetchCatalogByBaseUrls(baseUrl);
    }

    setGuestToken(token: string | null | undefined) {
        this.guestToken = token || null;
        return this;
    }

    setRecommendationsFlag(value: string) {
        this.recommendationsFlag = value;
        return this;
    }
}
