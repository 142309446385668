//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex";
import LogoMobile from "~/components/icons/LogoMobile.vue";
import { sendYandexMetric } from "~/service/ecommerce";
import config from "~/config";
import AllCustomButtons from "~/components/common/AllCustomButtons.vue";
import CrossBig from "~/components/icons/CrossBig.vue";

export default {
    name: "MobileAppBanner",
    components: { CrossBig, AllCustomButtons, LogoMobile },
    data: () => ({}),
    computed: {
        ...mapGetters("modals", ["anyOpenModals"]),
        getLink() {
            return config.APP_LINK;
        },
        iOS() {
            return this.$device.isIos;
        },
        isAndroid() {
            return this.$device.isAndroid;
        },
    },
    methods: {
        ...mapActions("account", ["setAppBanner"]),
        closeBanner() {
            sendYandexMetric("close-banner-heder-app");
            this.setAppBanner(false);
        },
        redirect() {
            if (process.browser) {
                window.location.href = this.getLink;
            }
        },
    },
};
