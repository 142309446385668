import { ActionTree, GetterTree, MutationTree } from "vuex";
import { Route } from "vue-router";
import Vue from "vue";
import { MetaObjectValues, MetaState } from "~/store_types/meta.types";
import { MetaStorage } from "~/service/storages/meta-storage";
import { RootState } from "~/store_types/index.types";
import cityLinksHelper, { EKD_CITY_LINK_ITEM } from "~/util/city-links-helper";
import { CatalogItemType, CatalogRouterItem } from "~/service/catalog-router/types";
import { BLACKLIST_ROUTE_PARTS, getPageTitleFromRoute } from "~/service/meta-helpers";

// Если просто пихать $router.currentRoute в state во время вызова onRouteChangeMeta,
// то при получении его через getter будет возникать ошибка "Maximum stack size exceed"
const _route = Vue.observable({ route: undefined });

export const state: () => MetaState = () => ({
    host: "www.eda1.ru",
    title: "",
    componentTitle: null,
    description: "",
    keywords: "",
    catalogItem: null,
    ogImage: null,
    metaBySuDocument: null,
    // cityMetaData: null,

    customMeta: {},
    customLinks: {},
});

export const getters: GetterTree<MetaState, RootState> = {
    currentRoute() {
        if (_route && _route.route) {
            return _route.route;
        }
        return null;
    },
    currentCityItemPrepos(state, getters, rootState) {
        const cityItem = cityLinksHelper
            .getCityItems()
            .filter((item) => item.id == (rootState.delivery.city?.id || EKD_CITY_LINK_ITEM.id))[0];
        return cityItem.prepos;
    },
    isCategory(state): boolean {
        if (state.catalogItem) {
            return state.catalogItem.type === "CATEGORY";
        }
        return false;
    },

    isCatalogPage(state): boolean {
        return !!state.catalogItem;
    },
    isNeedPageMeta(state, getters): boolean {
        const path = getters.currentRoute?.path;
        if (!path) {
            return false;
        }

        for (const blacklist of BLACKLIST_ROUTE_PARTS) {
            if (path.includes(blacklist)) {
                return false;
            }
        }
        return true;
    },
    isPagesComponent(state, getters): boolean {
        if (!getters.currentRoute) {
            return false;
        }
        return getters.currentRoute.matched.some((m) => m.name === "pages");
    },
    meta(state, getters) {
        const output = {
            description: state.description,
            keywords: state.keywords,
            "og:title": state.title,
            "og:description": state.description,
            "og:type": "article",
            "og:url": `${state.host}${getters.currentRoute?.fullPath}`,
            "og:site_name": state.host,
        };

        if (state.ogImage) {
            output["og:image"] = state.ogImage;
        }

        Object.assign(output, state.customMeta || {});

        return Object.keys(output).map((key) => {
            return { hid: key, name: key, content: output[key] };
        });
    },
    links(state) {
        // return metaLinks
        return Object.keys(state.customLinks).map((key) => {
            let value = state.customLinks[key];

            // Хак для canonical на главную страницу
            if (key === "canonical" && typeof value === "string" && value.endsWith(".ru")) {
                value += "/";
            }
            return { rel: key, href: value };
        });
    },
    metaCurrentPageByCity(state, getters, rootState) {
        return state.metaBySuDocument?.find((item) => +item.city_id === rootState.delivery.city?.id);
    },
    metaCurrentPageByUrl(state, getters) {
        return getters.metaCurrentPageByCity?.metatags?.find((item) => getters.currentRoute?.path === item?.url);
    },
};

export const mutations: MutationTree<MetaState> = {
    setHost(state, value: string) {
        state.host = value;
    },
    setCatalogItem(state, value: CatalogRouterItem | null) {
        state.catalogItem = value;
    },
    setTitle(state, value: string) {
        state.title = value;
    },
    setDescription(state, value: string) {
        state.description = value;
    },
    setKeywords(state, value: string) {
        state.keywords = value;
    },
    setCustomMeta(state, value: any) {
        state.customMeta = value;
    },
    setCustomLinks(state, value: any) {
        state.customLinks = value;
    },
    mergeCustomLinks(state, value: any) {
        state.customLinks = Object.assign({}, state.customLinks, value);
    },
    setOGImage(state, value: string | null) {
        state.ogImage = value;
    },
    setComponentTitle(state, value: string | null) {
        state.componentTitle = value;
    },
    setCanonicalLink(state, value) {
        Vue.set(state.customLinks, "canonical", value);
    },
    setMetaBySuDocument(state, value) {
        state.metaBySuDocument = value;
    },
    // setCityMetaData(state, value: any) {
    //     state.cityMetaData = value;
    // },
};

export const actions: ActionTree<MetaState, RootState> = {
    async setCategoryMeta({ state, dispatch, getters, rootState }) {
        const metaCategoryFromSuDoc = state.catalogItem?.id
            ? getters.metaCurrentPageByCity?.categories?.[state.catalogItem.id]
            : null;

        if (metaCategoryFromSuDoc) {
            await dispatch("fillValuesByObject", metaCategoryFromSuDoc);
        } else {
            let categoryName = "вкусную еду";
            const phone =
                rootState.delivery.city?.phonesMobile ||
                rootState.delivery.city?.phonesUrban ||
                rootState.delivery.city?.phonesHotline;
            if (rootState.products?.categories && state.catalogItem) {
                for (const menuCategory of rootState.products.categories) {
                    if (menuCategory.id == state.catalogItem.id) {
                        categoryName = menuCategory.title;
                        break;
                    }
                }
            }
            const metaData = {
                title: "Заказать " + categoryName + " — доставка еды на дом в " + getters.currentCityItemPrepos,
                description:
                    "Заказать " +
                    categoryName +
                    " в " +
                    getters.currentCityItemPrepos +
                    " — быстрая и бесплатная доставка заказов на дом и офис от службы EDA1.RU. Только свежие ингредиенты. Звоните! " +
                    phone,
            };
            await dispatch("fillValuesByObject", metaData);
        }
    },

    async setProductMeta({ state, dispatch, getters, rootState, rootGetters }) {
        let productName = "вкусную еду";
        let productCategoryName = "";
        const phone =
            rootState.delivery.city?.phonesMobile ||
            rootState.delivery.city?.phonesUrban ||
            rootState.delivery.city?.phonesHotline;
        if (rootState.products.products && state.catalogItem) {
            for (const menuProduct of rootState.products.products) {
                if (menuProduct.id == state.catalogItem.id) {
                    productName = menuProduct.name;
                    const productCategory = rootGetters["products/getCategories"].find(
                        (category) => menuProduct.category_id == category.id
                    );
                    if (productCategory) {
                        productCategoryName = `${productCategory.title} — `;
                    }
                    break;
                }
            }
        }

        const metaData = {
            title:
                "Заказать " +
                productCategoryName +
                productName +
                " с бесплатной доставкой в " +
                getters.currentCityItemPrepos,
            description:
                "Заказать " +
                productCategoryName +
                productName +
                " в " +
                getters.currentCityItemPrepos +
                " — быстрая и бесплатная доставка заказов на дом и офис от службы EDA1.RU. Только свежие ингредиенты. Звоните! " +
                phone,
        };
        await dispatch("fillValuesByObject", metaData);
    },

    async setTextPageMeta({ state, dispatch, getters, rootState }, title) {
        const phone =
            rootState.delivery.city?.phonesMobile ||
            rootState.delivery.city?.phonesUrban ||
            rootState.delivery.city?.phonesHotline;
        const metaData = {
            title: title + " — служба EDA1.RU в " + getters.currentCityItemPrepos,
            description:
                title +
                " — служба EDA1.RU в " +
                getters.currentCityItemPrepos +
                ". Быстрая и бесплатная доставка заказов на дом и офис. Звоните! " +
                phone,
        };
        await dispatch("fillValuesByObject", metaData);
    },

    findCatalogItem({ getters, commit }) {
        if (!getters.currentRoute) {
            return null;
        }
        const catalogItem = this.$catalogRouter.getItemByPath(cityLinksHelper.cleanCityPath(getters.currentRoute.path));
        commit("setCatalogItem", catalogItem || null);
    },
    fillValuesByObject({ commit }, data: MetaObjectValues) {
        if (data.title) {
            commit("setTitle", data.title);
        }
        if (data.description) {
            commit("setDescription", data.description);
        }
        if (data.keywords) {
            commit("setKeywords", data.keywords);
        }
    },

    async onRouteChangeMeta({ state, getters, commit, dispatch, rootState }, route: Route | undefined = undefined) {
        Vue.set(_route, "route", route);

        if (process.client && getters.isPagesComponent) {
            // На клиенте не позволяем менять мета-данные для страничек pages.vue
            return;
        }

        commit("setComponentTitle", getPageTitleFromRoute(route) || null);
        commit("setCustomMeta", {});
        commit("setCustomLinks", {});
        await dispatch("findCatalogItem");

        if (!state.metaBySuDocument) {
            await dispatch("getMetaBySuDocument");
        }

        if (getters.metaCurrentPageByUrl) {
            await dispatch("setCommonCanonicalLink");
            await dispatch("fillValuesByObject", getters.metaCurrentPageByUrl);
        } else {
            await dispatch("setCommonValues");
        }

        // rootState.delivery.city.id
        if (getters.isCatalogPage) {
            await dispatch("setCatalogOGImage");
            if (getters.isCategory) {
                await dispatch("setCategoryMeta");
                return;
            }

            if (!getters.metaCurrentPageByUrl) {
                await dispatch("setProductMeta");
            }
        }

        // await dispatch("setPriorityValues");
    },

    async getMetaBySuDocument({ state, commit, getters, rootState }) {
        const storageMetatags = await new MetaStorage().get();
        commit("setMetaBySuDocument", storageMetatags);
    },

    // async setPriorityValues({ state, getters, dispatch }) {
    //     const currentRoutWithOutSlash = getters.currentRoute.path.replace("/", "");
    //     if (state.cityMetaData && state.cityMetaData[currentRoutWithOutSlash]?.url === currentRoutWithOutSlash) {
    //         await dispatch("fillValuesByObject", state.cityMetaData[currentRoutWithOutSlash]);
    //     }
    // },
    // COMMON VALUES
    async setCommonValues({ state, commit, dispatch, rootState }) {
        const storedCity = rootState.delivery.city;
        const cityName = storedCity?.name || "Екатеринбург";

        const prepos = cityLinksHelper.getCityPrepositionalTitle(storedCity?.id || null);
        const pageTitle = state.componentTitle || "Сушкоф";

        let title = `${pageTitle} — доставка еды`;
        let description = pageTitle;
        if (prepos) {
            title = `${title} в ${prepos}`;
            description = `${description} в ${prepos}`;
        }

        description = `${description} — быстрая и бесплатная доставка заказов на дом и офис от службы EDA1.RU. Только свежие ингредиенты`;
        if (storedCity) {
            description = `${description}. Звоните! ${storedCity.phonesUrban}`;
        }

        commit("setTitle", title);
        commit("setDescription", description);
        commit("setKeywords", `заказ еды дом ${cityName.toLowerCase()} круглосуточно доставка блюд ресторан`);

        commit("setOGImage", `https://${state.host}/images/sushkoff-og.jpg`);
        await dispatch("setCommonCanonicalLink");
    },
    setCommonCanonicalLink({ state, getters, commit }) {
        if (!getters.currentRoute) {
            return;
        }

        const url = `https://${state.host}${getters.currentRoute.path}`;
        commit("setCanonicalLink", url);
    },

    // CATALOG
    async setCatalogValues({ state, dispatch }) {
        if (!state.catalogItem) {
            return;
        }

        await Promise.all([dispatch("setCatalogOGImage")]);
    },
    setCatalogOGImage({ state, commit, rootGetters }) {
        if (state.catalogItem?.type !== CatalogItemType.PRODUCT) {
            return;
        }

        const product = rootGetters["products/catalogProductModels"].find((p) => p.id == state.catalogItem?.id);
        if (!product) {
            return;
        }

        commit("setOGImage", product.photoSmall);
    },
};
