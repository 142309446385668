//
//
//
//
//
//
//
//
//
//
//
//
//

import GoulashTechIcon from "~/components/icons/GoulashTechIcon.vue";
import PoweredByIcon from "~/components/icons/PoweredByIcon.vue";
import { sendYandexMetric } from "~/service/ecommerce";

export default {
    name: "GoulashTechLink",
    components: { PoweredByIcon, GoulashTechIcon },
    props: {
        type: {
            type: String,
            default: "column",
            validator(value) {
                return ["column", "row"].includes(value);
            },
        },
    },
    methods: {
        onClick() {
            sendYandexMetric("goulash-click");
        },
    },
};
