//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from "~/config";
export default {
    name: "AppLinks",
    props: {
        redirect: {
            type: Boolean,
            default: false,
        },
        location: {
            type: String,
        },
    },
    computed: {
        getLink() {
            return config.APP_LINK;
        },
        downloadLinksShow() {
            if (this.isMobile) {
                return {
                    apple_store: this.iOS,
                    google_store: this.isMobile && !this.iOS,
                    app_gallery: this.isMobile && !this.iOS,
                };
            }

            return {
                apple_store: true,
                google_store: true,
                app_gallery: true,
            };
        },
        iOS() {
            return this.$device.isIos;
        },
        isMobile() {
            return this.$device.isMobile;
        },
    },
    methods: {
        downloadApp() {
            if (this.redirect && !this.isMobile) {
                this.$router.push("/mobile_app/");
            } else if (!this.redirect && !this.isMobile) {
                return null;
            } else {
                window.open(this.getLink, "_blank");
            }
        },
    },
};
