//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapState } from "vuex";
import Product from "~/models/Product";
import cityLinksHelper from "~/util/city-links-helper";
import ProductActions from "~/components/products/ProductActions.vue";
import ProductTags from "~/components/products/ProductTags.vue";

export default {
    name: "SearchProduct",
    components: { ProductTags, ProductActions },
    props: ["product", "tags", "type"],
    computed: {
        ...mapState("metrika", ["paramsAB"]),
        ...mapGetters("cart", ["closestDepartments", "isCartLoaded", "productsInCart"]),
        productPhoto() {
            return new Product(this.product).photoSmall;
        },
        productLink() {
            return cityLinksHelper.getNuxtLinkToPath(
                this.$catalogRouter.getProductPath(this.product),
                cityLinksHelper.getCityIdFromVuex(this.$store)
            );
        },
        productIsDisabled() {
            if (!this.product.remainings || !this.closestDepartments?.length) {
                return;
            }

            return this.closestDepartments.every((department) => {
                return (
                    (this.product.remainings?.[department.id] !== null && department.active) || !department.active
                );
            });
        },
        productCount() {
            const products = this.productsInCart.filter((product) => product.id === this.product.id);

            let count = null;

            products.forEach((product) => {
                count += product.quantity;
            });

            return count;
        },
    },
    methods: {
        ...mapMutations("productPopup", ["setOpenIn"]),
        showTooltip(id) {
            const text = { 102: "на самовывоз" };
            if (text[id]) {
                this.$emit("showTooltip", {
                    text: text[id],
                    trigger: this.$refs[`product__type${id}`][0],
                });
            }
        },
        showMiniCart(ev) {
            this.$emit("showMiniCart", ev);
        },
        hideMiniCart() {
            this.$emit("hideMiniCart");
        },
        toggleMiniCart(ev) {
            this.$emit("toggleMiniCart", ev);
        },
        async onProductClick() {
            this.setOpenIn(this.$route.path);
            await this.$router.push({ path: this.productLink });
        },
    },
};
