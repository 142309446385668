//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ProductTags",
    props: {
        product: {},
        modProduct: {},
        tags: {
            type: Array,
            default: null,
        },
        weightOnly: {
            type: Boolean,
            default: false,
        },
        checkShowWeight: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tooltipTrigger: null,
            tooltipText: "",
            isShowTooltip: false,
        };
    },
    computed: {
        weight() {
            return this.modProduct ? this.modProduct.info.weight : this.product.info.weight;
        },
        showWeight() {
            const weight = this.weight;
            const weightNoEmpty = typeof weight === "string" && weight.trim() !== "" && weight.trim() !== "0";

            if (this.product && this.product?.modifier_groups) {
                const flag = this.product.modifier_groups.some((group) => {
                    return group.assemble_yourself_combo;
                });
                return !flag && weightNoEmpty;
            }
            return weightNoEmpty;
        },
        visualTags() {
            if (this.product.tags && this.tags) {
                const visualTags = this.tags.filter((tag) => {
                    return this.product.tags.includes(tag.id) && tag?.icon;
                });

                return visualTags;
            }

            return false;
        },
        selfDiscount() {
            return this.product?.self_discount_percent;
        },
        hasNoComplementMods() {
            const hasMainModGroup = this.product?.modifier_groups.find(
                (group) => !group.is_complement && !group.allow_stacking
            );

            return (
                hasMainModGroup &&
                this.product?.available_modifiers.filter((mod) => mod.group_id === hasMainModGroup.id).length > 1
            );
        },
        isShowWeight() {
            return this.checkShowWeight ? this.showWeight && !this.hasNoComplementMods : this.showWeight;
        },
    },
    methods: {
        showTooltip(id) {
            const tag = this.visualTags.find((tag) => tag.id === id);
            if (this.visualTags && tag && tag?.description) {
                this.tooltipTrigger = this.$refs[`product__type${id}`][0];
                this.tooltipText = tag.description;
                this.isShowTooltip = true;
            }
        },
        hideTooltip() {
            this.tooltipTrigger = null;
            this.tooltipText = "";
            this.isShowTooltip = false;
        },
    },
};
