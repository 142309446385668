export enum CatalogItemType {
    CATEGORY = "CATEGORY",
    PRODUCT = "PRODUCT",
    TAG = "TAG",
}

export interface CatalogRouterItem {
    id: number;
    type: CatalogItemType;
    paths: PathItem[];
    categoryId?: number | undefined;
    isSelfCanonical?: boolean; // Используется в мета-данных canonical
    isPredefined?: boolean; // У категории: true - если в СУ указали URL
}

export interface CatalogItem {
    id: number;
}

export interface CatalogCategory extends CatalogItem {
    title: string;
    parent_id?: number;
    url?: string;
}

export interface CatalogProduct extends CatalogItem {
    name: string;
    price: number;
    category_id: number;
    categories_ids: number[];
}

export interface CatalogTag extends CatalogItem {
    title: string;
}

export type PathItem = string;
