import { Store } from "vuex";
import { UrlItems } from "../storages/urls-storage/types";
import { UrlsStorage } from "../storages/urls-storage/index";
import { transliterate } from "../../util/helpers";
import { cloneDeep } from "../../util/clone";
import { CatalogApiRequestResult, CatalogBaseInfo, MenuCategory, MenuProduct, Tag } from "../../util/api.types";
import { trimUrlSlashes } from "../sitemap-generator/helpers";
import { SharedContextData } from "../storages/abstract-storage/shared-context-data";
import { RootState } from "../../store_types/index.types";
import { CatalogGetter } from "../catalog-getter/index";
import { PathsArray } from "./paths-array";
import { CatalogCategory, CatalogItemType, CatalogProduct, CatalogTag, CatalogRouterItem } from "./types";

interface CategoriesMap {
    [key: string]: CatalogRouterItem;
}

interface CatalogRouterSourceData {
    catalog: CatalogBaseInfo;
    urls: UrlItems;
}

export class CatalogRouter {
    private items: CatalogRouterItem[] = [];

    private categoriesMap: CategoriesMap = {};

    async fillBySharedContextData(contextData: SharedContextData) {
        let catalog: CatalogApiRequestResult | null = null;
        let urls: UrlItems | null = null;

        await Promise.all([
            (async () => {
                catalog = await CatalogGetter.createFromSharedContextData(contextData).get();
            })(),
            (async () => {
                urls = await new UrlsStorage().setSharedContextData(contextData).get();
            })(),
        ]);

        if (!catalog || !urls) {
            return;
        }

        this.fill({ catalog, urls });
    }

    fillByVuexStore(store: Store<RootState>) {
        const categories: MenuCategory[] = store.state.products?.categories || [];
        const products: MenuProduct[] = store.state.products?.products || [];
        const tags: Tag[] = store.getters["products/getGeneralTags"] || [];

        const catalog: CatalogBaseInfo = { categories, products, tags };
        const urls = store.state.products?.urls;

        this.fill({ catalog, urls });
    }

    private fill(sourceData: CatalogRouterSourceData) {
        if (!sourceData) {
            return;
        }

        this.items.splice(0, this.items.length);

        this.fillCategories(sourceData.catalog?.categories, sourceData.urls);
        this.fillProducts(sourceData.catalog?.products, sourceData.urls);
        this.fillTags(sourceData.catalog, sourceData.urls);
    }

    getItemBestPath(item: CatalogRouterItem): string | undefined {
        return new PathsArray(item.paths).best();
    }

    getItemByPath(path: string): CatalogRouterItem | undefined {
        return this.items.find((ri) => ri.paths.includes(path));
    }

    getCategoryByPath(path: string): CatalogRouterItem | undefined {
        return this.items.find((item) => {
            if (item.type === CatalogItemType.CATEGORY) {
                return item.paths.find((i) => path.includes(i));
            }
        });
    }

    doesPathExist(path: string): boolean {
        return !!this.getItemByPath(path);
    }

    getItems() {
        return cloneDeep(this.items);
    }

    private setUrlsValue(urls: UrlItems, key: string, value: string) {
        if (process.client) {
            return;
        }

        urls[key] = value;
    }

    private isAbsoluteSlug(slug: string | undefined) {
        return typeof slug === "string" && slug.slice(0, 1) === "/";
    }

    private findRouterItem(itemId: number, type: CatalogItemType, categoryId?: number) {
        return this.items.find(
            (ri) => ri.id == itemId && ri.type == type && (!categoryId || categoryId === ri.categoryId)
        );
    }

    // Поменяем формат названия для url (из кирилицы в латиницу)
    private itemNameToSlug(pName: string) {
        const WHITESPACE_REPLACER = "-";
        const LENGTH_LIMIT = 25;

        const slug = transliterate(pName.toLowerCase())
            .replace(/[^0-9a-z]/g, " ")
            .replace(/\s+/g, WHITESPACE_REPLACER);

        if (slug.length <= LENGTH_LIMIT) {
            return slug;
        }

        let lastReplacerIndex: number | null = null;
        for (let i = LENGTH_LIMIT; i > 0; i--) {
            if (slug[i] === WHITESPACE_REPLACER) {
                lastReplacerIndex = i;
                break;
            }
        }

        if (lastReplacerIndex === null || lastReplacerIndex < 5) {
            return slug.substr(0, LENGTH_LIMIT);
        }

        return slug.substr(0, lastReplacerIndex);
    }

    // #Категории
    // url категории на основе id
    private getCategoryDraftPath(category: CatalogCategory): string {
        return `/${category.parent_id || 0}/${category.id}`;
    }

    // url категории на основе поля "url" в категории
    private getCategoryPredefinedPath(category: CatalogCategory): string | null {
        if (!category.url) {
            return null;
        }
        return `/${trimUrlSlashes(category.url)}`;
    }

    // url категории на основе объекта из запроса класса UrlsStorage
    private getCategoryVirtualPath(category: CatalogCategory, urls: UrlItems): string | null {
        const key = `${CatalogItemType.CATEGORY}_${category.id}`;
        return urls[key] || null;
    }

    public getCategoryPath(category: CatalogCategory) {
        const categoryId = category.id;

        const routerItem: CatalogRouterItem | undefined = this.findRouterItem(categoryId, CatalogItemType.CATEGORY);

        if (routerItem) {
            return new PathsArray(routerItem.paths).best();
        }
        return this.getCategoryPredefinedPath(category) || this.getCategoryDraftPath(category);
    }

    public getCategoryPathById(categoryId): string | undefined {
        const routerItem: CatalogRouterItem | undefined = this.findRouterItem(categoryId, CatalogItemType.CATEGORY);
        if (!routerItem) {
            return undefined;
        }

        return new PathsArray(routerItem.paths).best();
    }

    private fillCategories(categories: CatalogCategory[], urls: UrlItems) {
        this.categoriesMap = {};

        for (const category of categories) {
            const item: CatalogRouterItem = {
                id: category.id,
                type: CatalogItemType.CATEGORY,
                paths: [],
            };

            const pathsArray = new PathsArray([]);

            const predefined = this.getCategoryPredefinedPath(category);
            if (predefined) {
                pathsArray.addBetter(predefined);
                item.isPredefined = true;
            }

            const virtualPath = this.getCategoryVirtualPath(category, urls);
            if (virtualPath) {
                pathsArray.addWorse(virtualPath);
            }

            pathsArray.addWorse(this.getCategoryDraftPath(category));

            item.paths = pathsArray.getArray();

            this.categoriesMap[category.id] = item;
            this.items.push(item);
        }
    }

    // #Продукты
    // url продукта на основе id
    private getProductDraftPath(
        product: CatalogProduct,
        categoryItem: CatalogRouterItem | undefined = undefined
    ): string {
        return categoryItem
            ? `${new PathsArray(categoryItem.paths).worst()}/${product.id}`
            : `/0/${product.category_id}/${product.id}`;
    }

    // url продукта на основе названия, но латиницей
    private generateProductSlugPart(product: CatalogProduct): string {
        return `${product.id}-${this.itemNameToSlug(product.name)}`;
    }

    private getProductItemKey(product: CatalogProduct): string {
        return `${CatalogItemType.PRODUCT}_${product.id}`;
    }

    // url продукта с категорией
    private getProductVirtualPath(
        product: CatalogProduct,
        categoryItem: CatalogRouterItem | undefined,
        urls: UrlItems
    ): string | null {
        const key = this.getProductItemKey(product);
        let slug = urls[key];

        const needGenerateSlug = !slug || (categoryItem && categoryItem.isPredefined && this.isAbsoluteSlug(slug));

        if (needGenerateSlug) {
            slug = this.generateProductSlugPart(product);
            this.setUrlsValue(urls, key, slug);
        }

        if (this.isAbsoluteSlug(slug)) {
            return slug;
        }

        const categoryPath = categoryItem ? new PathsArray(categoryItem.paths).best() : null;
        if (!categoryPath) {
            // TODO Alex: Можно попробовать взять draftPath текущего продукта, последнюю часть заменить на slug
            console.log("** category id", product.category_id, "not found in categoriesMap");
            return null;
        }

        return `${categoryPath}/${slug}`;
    }

    private hasProductSelfGeneratedSlug(product: CatalogProduct, urls: UrlItems): boolean {
        const key = this.getProductItemKey(product);
        const slug = urls[key];

        if (!slug) {
            return false;
        }

        return slug.substr(0, 1) !== "/";
    }

    public getProductPath(product: CatalogProduct) {
        const productId = product.id;

        const routerItem: CatalogRouterItem | undefined = this.findRouterItem(productId, CatalogItemType.PRODUCT);

        if (routerItem) {
            return new PathsArray(routerItem.paths).best();
        }

        const draftPath = this.getProductDraftPath(product);
        return draftPath.substr(0, 1) + ("d" + this.items.length + "-") + draftPath.substr(1);
    }

    public getProductPathById(productId): string | undefined {
        const routerItem: CatalogRouterItem | undefined = this.findRouterItem(productId, CatalogItemType.PRODUCT);
        if (!routerItem) {
            return undefined;
        }

        return new PathsArray(routerItem.paths).best();
    }

    private findCategoryItemForProductInCategoriesMap(product: CatalogProduct): CatalogRouterItem | undefined {
        // Соберём все категории у товара
        const categoryIds = product.categories_ids ? [...product.categories_ids] : [];
        if (product.category_id) {
            categoryIds.push(product.category_id);
        }

        // Поищем, какая из категорий у нас есть в каталоге
        for (const categoryId of categoryIds) {
            if (this.categoriesMap[categoryId] !== undefined) {
                return this.categoriesMap[categoryId];
            }
        }

        return undefined;
    }

    private fillProducts(products: CatalogProduct[], urls: UrlItems) {
        for (const product of products) {
            const productCategoryItem = this.findCategoryItemForProductInCategoriesMap(product);

            const item: CatalogRouterItem = {
                id: product.id,
                type: CatalogItemType.PRODUCT,
                paths: [],
                isSelfCanonical: this.hasProductSelfGeneratedSlug(product, urls),
            };

            const pathsArray = new PathsArray([]);
            pathsArray.addWorse(this.getProductDraftPath(product, productCategoryItem));

            const virtualPath = this.getProductVirtualPath(product, productCategoryItem, urls);
            if (virtualPath) {
                pathsArray.addBetter(virtualPath);
            }

            item.paths = pathsArray.getArray();
            this.items.push(item);
        }
    }

    // #TAG
    public getTagPath(tagId: number, categoryId: number) {
        const routerItem: CatalogRouterItem | undefined = this.findRouterItem(tagId, CatalogItemType.TAG, categoryId);

        if (routerItem) {
            return new PathsArray(routerItem.paths).best();
        }
    }

    // url тега на основе названия, но латиницей
    private generateTagSlugPart(tag: CatalogTag): string {
        return `${tag.id}-${this.itemNameToSlug(tag.title)}`;
    }

    getTagItemKey(tag: CatalogTag) {
        return `${CatalogItemType.TAG}_${tag.id}`;
    }

    private getTagVirtualPath(tag: Tag, categoryItem: CatalogRouterItem, urls) {
        const key = this.getTagItemKey(tag);
        let slug = urls[key];
        const needGenerateSlug = !slug || (categoryItem?.isPredefined && this.isAbsoluteSlug(slug));

        if (needGenerateSlug) {
            slug = this.generateTagSlugPart(tag);
            this.setUrlsValue(urls, key, slug);
        }

        if (this.isAbsoluteSlug(slug)) {
            return slug;
        }

        const categoryPath = categoryItem ? new PathsArray(categoryItem.paths).best() : null;
        if (!categoryPath) {
            return null;
        }

        return `${categoryPath}/${slug}`;
    }

    private findCategoryItemForTagInCategoriesMap(catalog: CatalogBaseInfo, tag: CatalogTag) {
        const tagCategory = catalog.categories.filter((category) => category.tags?.find((t) => t.id === tag.id));
        return tagCategory.map((category) => this.categoriesMap[category?.id]);
    }

    private fillTags(catalog: CatalogBaseInfo, urls) {
        if (!catalog.tags) {
            return;
        }
        catalog.tags.forEach((tag: Tag) => {
            // К тегу может относится несколько категорий
            const tagCategoryItem = this.findCategoryItemForTagInCategoriesMap(catalog, tag);

            tagCategoryItem.forEach((category) => {
                const item: CatalogRouterItem = {
                    id: tag.id,
                    type: CatalogItemType.TAG,
                    paths: [],
                    categoryId: category.id,
                };

                const pathsArray = new PathsArray([]);
                pathsArray.addWorse(`/0/${category?.id}/${tag.id}`);

                const virtualPath = this.getTagVirtualPath(tag, category, urls);
                if (virtualPath) {
                    pathsArray.addBetter(virtualPath);
                }

                item.paths = pathsArray.getArray();
                this.items.push(item);
            });
        });
    }
}
