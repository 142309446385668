//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from "vuex";
import PaymentPay from "~/components/icons/PaymentPay.vue";
import PaymentSpb from "~/components/icons/PaymentSpb.vue";
import Logo from "~/components/icons/Logo.vue";
import PaymentMir from "~/components/icons/PaymentMir.vue";
import PaymentMastercard from "~/components/icons/PaymetnMastercard.vue";
import PaymentVisa from "~/components/icons/PaymetnVisa.vue";
import Container from "~/components/common/Container.vue";
import AppQr from "~/components/icons/AppQr.vue";
import GoulashTechLink from "~/components/GoulashTechLink.vue";
import AppLinks from "~/components/AppLinks.vue";
// import LogoNg from "~/components/icons/LogoNg.vue";

export default {
    name: "FooterSection",
    components: {
        AppLinks,
        GoulashTechLink,
        Logo,
        AppQr,
        Container,
        PaymentVisa,
        PaymentMastercard,
        PaymentMir,
        PaymentSpb,
        PaymentPay,
    },
    props: ["newCity"],
    computed: {
        ...mapState("delivery", ["city"]),
        ...mapGetters("delivery", ["selfDeliveryDepartments"]),
        phones() {
            if (!this.city) {
                return [];
            }
            return {
                hotline: [...this.city.phonesHotline].filter((p) => !!p),
                delivery: [...this.city.phonesUrban, ...this.city.phonesMobile].filter((p) => !!p),
            };
        },
        isNewCityPage() {
            return this.$route.name === "new_city";
        },
        isMobile() {
            return this.$device.isMobile;
        },
    },
    methods: {
        ...mapActions("account", ["showPersonalDataProcessingAgreement"]),
    },
};
