//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import cartUtils from "~/util/cart";
import config from "~/config";

export default {
    name: "ExternalPhoneConfirm",
    data() {
        return {
            payload: {},
            ws: null,
        };
    },
    computed: {
        ...mapState(["isMobileBrowser"]),
        phone() {
            return this.getDefaultNull("phone");
        },
        transport() {
            return this.getDefaultNull("transport");
        },
        onSuccess() {
            return this.getDefaultNull("callback");
        },
        confirmMessage() {
            return this.getDefaultNull("confirmMessage");
        },
        ignoreConfirmationMessage() {
            return this.getDefaultNull("ignoreConfirmationMessage");
        },
        isOrderRequest() {
            return this.payload.isOrderRequest || false;
        },
    },
    mounted() {
        window.g_elt = () => {
            this.$nuxt.$emit("request-external-phone-confirm", {
                phone: "+7 (922) 030-64-40",
                transport: "telegram",
                callback: (data) => {
                    console.log("succeed!", data);
                },
                isOrderRequest: true,
            });
        };
        this.$nuxt.$on("request-external-phone-confirm", (payload) => {
            this.payload = payload;
            this.start();
        });
    },
    methods: {
        getDefaultNull(field) {
            return this.payload[field] || null;
        },
        async generateOrderRequest() {
            if (!this.isOrderRequest) {
                return null;
            }

            const baseUrl = this.$api.getBaseApiUrl();
            if (!baseUrl) {
                return null;
            }

            return {
                registerCheckUrl: `${baseUrl}/user/registerCheck`,
                sendOrderUrl: `${baseUrl}/cart/send`,
                orderDetailsUrl: `${baseUrl}/orders/details`,
                uuid: this.$api.getUid(),
                body: JSON.stringify(await cartUtils.getCartRequestData()),
            };
        },
        closeConnection() {
            try {
                this.ws.disconnect();
            } catch (err) {
                //
            } finally {
                this.ws = null;
            }
        },
        reset() {
            this.closeConnection();
            this.payload = {};
        },
        dispatchSocketError(error = null) {
            this.$store.dispatch("errors/addDisplayError", {
                errors: { "products.error_modal": error || "Ошибка подключения к сервису авторизации" },
                timeout: 10000,
            });
        },
        sendMetricMessage(goal) {
            if (this.transport === "telegram") {
                // sendYandexMetric(`telegram-${goal}`);
            }
            if (this.transport === "whatsapp") {
                // sendYandexMetric(`whatsup-${goal}`);
            }
        },
        start() {
            this.sendMetricMessage("start");
            if (this.ws) {
                this.closeConnection();
            }
            if (!this.phone || !this.transport) {
                return;
            }
            if (!config.CLIENT_AUTH_WS_URL) {
                return;
            }
            try {
                this.ws = new WebSocket(config.CLIENT_AUTH_WS_URL);
            } catch (err) {
                this.dispatchSocketError();
                return;
            }
            this.ws.onopen = () => {
                console.log("** connected!");
                this.requestAuth();
            };
            this.ws.onclose = (event) => {
                if (event.code === 1006) {
                    setTimeout(() => this.start(), 1000);
                } else {
                    console.log("** ws closed", event.code, event.reason);
                    this.closeConnection();
                }
            };
            this.ws.onerror = (err) => {
                console.log("** ws error", err.message);
                this.dispatchSocketError();
                this.closeConnection();
            };
            this.ws.onmessage = (event) => {
                console.log("** message", event.data);
                let data = {};
                try {
                    data = JSON.parse(event.data);
                } catch (err) {
                    return;
                }
                if (!data) {
                    return;
                }
                if (!data.action) {
                    return;
                }
                if (data.action == "confirm") {
                    // this.$store.dispatch('auth/externalLogin', data.body);
                    if (this.onSuccess && typeof this.onSuccess === "function") {
                        this.sendMetricMessage("end");
                        this.onSuccess(data.body);
                    }
                    this.reset();
                }
            };
        },
        async requestAuth() {
            if (!this.ws) {
                return;
            }
            this.ws.send(
                JSON.stringify({
                    action: "start",
                    body: {
                        phone: this.phone,
                        transport: this.transport,
                        confirmMessage: this.confirmMessage,
                        ignoreConfirmationMessage: this.ignoreConfirmationMessage,
                        orderRequest: await this.generateOrderRequest(),
                        isMobile: this.isMobileBrowser,
                    },
                })
            );
        },
    },
};
